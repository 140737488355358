/* CSS */
import "@css/app.pcss";
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* JS */
import 'lazysizes';
import { defineCustomElement } from 'vue';
import contactForm from '../components/contactForm.ce.vue'

/* SCRIPTS HERE */

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';
import swipePlugin from "alpinejs-swipe";


if (typeof window.Alpine === "undefined") {
  Alpine.plugin(collapse);
  Alpine.plugin(focus);
  Alpine.plugin(persist);
  Alpine.plugin(swipePlugin);
  window.Alpine = Alpine;
  window.Swiper = Swiper;  
  Alpine.start();
}

/* VUE Contact Form */

const contactFormElement = defineCustomElement(contactForm);
customElements.define('contact-form', contactFormElement);

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

